import ProfileComp from "../admin/admincomponent/Profile/ProfileComp";
import UserTicket from "../jsx/components/Users/UserTicket";

export const allUserroutes = [
  {
    url: "profile",
    component: <ProfileComp />,
  },
  {
    url: "user-grievance-management",
    component: <UserTicket />,
  },
];
