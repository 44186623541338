import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';

import swal from "sweetalert";
import { Modal } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import { updateCategoryType } from '../../../services/superadminService/CategoreyType';
import { updateSubCategory } from '../../../services/superadminService/SubCategory';
import { useFormik } from 'formik';
import * as Yup from 'yup';
const EditSubCategoryModal = ({ showEditModal, setShowEditModal, ClientId, subCategoryIdData, CategorySubListApi }) => {
    const [initialValues, setInitialValues] = useState({
      category: '',
      category_id: '',
      slug: '',
      status: '',
    });
  
    useEffect(() => {
      if (subCategoryIdData) {
        setInitialValues({
          category: subCategoryIdData?.sub_category_name || '',
          category_id: subCategoryIdData?.user_category_types_id || '',
          slug: subCategoryIdData?.url_slug || '',
          status: subCategoryIdData?.active_status || '',
        });
      }
    }, [subCategoryIdData]);
  
    const validationSchema = Yup.object({
      category: Yup.string().required('Please fill category name'),
      category_id: Yup.string().required('Please select a parent category'),
      slug: Yup.string().required('Please fill slug'),
      status: Yup.string().required('Please select status'),
    });
  
    const formik = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: async (values, { setSubmitting }) => {
        const editData = {
          sub_category_name: values.category,
          user_category_types_id: values.category_id,
          category_name_view: 'athlites',
          active_status: values.status,
          user_level_id: 1,
          url_slug: values.slug,
        };
  
        try {
          const response = await updateSubCategory(editData, ClientId);
          if (response?.data?.status === true) {
            swal(`${response?.data?.message}`);
            CategorySubListApi();
            setShowEditModal(false);
          }
        } catch (error) {
          console.log("error", error);
        } finally {
          setSubmitting(false);
        }
      },
    });
  
    return (
      <Modal className="modal fade" show={showEditModal} onHide={() => setShowEditModal(false)}>
        <div className="" role="document">
          <div className="">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit SubCategory</h4>
                <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} data-dismiss="modal"><span></span></button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label className="text-black font-w500">Category Name</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="category"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.category}
                            placeholder="name"
                          />
                          {formik.touched.category && formik.errors.category ? (
                            <div className="validation-text">{formik.errors.category}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label className="text-black font-w500">Parent Category</label>
                        <div className="contact-name">
                          <select
                            name="category_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.category_id}
                            className="form-control"
                          >
                            <option value="">select category Parent</option>
                            <option value="1">Individual</option>
                            <option value="2">Institute</option>
                          </select>
                          {formik.touched.category_id && formik.errors.category_id ? (
                            <div className="validation-text">{formik.errors.category_id}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label className="text-black font-w500">Status</label>
                        <div className="contact-name">
                          <select
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                            className="form-control"
                          >
                            <option value="">select status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                          {formik.touched.status && formik.errors.status ? (
                            <div className="validation-text">{formik.errors.status}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label className="text-black font-w500">Slug</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="slug"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.slug}
                            placeholder="Slug"
                          />
                          {formik.touched.slug && formik.errors.slug ? (
                            <div className="validation-text">{formik.errors.slug}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? 'Saving...' : 'Save'}
                </button>
                <button type="button" onClick={() => setShowEditModal(false)} className="btn btn-danger">
                  <i className="flaticon-delete-1"></i> Discard
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  };
  
  export default EditSubCategoryModal;
