import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

// image
import logo from "../../images/jharkhandlogo1.png";
import loginbg from "../../images/pic1.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import notify from "../../utils/notification";
import { PurpleButton, RedButton } from "../../common/ui/view/CustomButton";
import { EyePasswordBtn } from "../../common/ui/view/CustomEyePasswordBtn";

function Login({ props, apifun }) {
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [showPassword, setShowPassword] = useState(false);

  const [token, setToken] = useState(localStorage.getItem("access_token_admin"));
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "sajhadb@yopmail.com",
    password: "adminlock##",
  });

  const handleSignupApiCall = (e) => {
    console.log("signup btn click");
  };

  const handleForgetPasswordApiCall = (e) => {
    console.log("forget btn click");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (loginData.email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (loginData.password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    const data = {
      email: loginData?.email,
      password: loginData?.password,
    };

    try {
      const response = await apifun(data);
      if (response?.status === 200) {
        notify("success", response.data.message);
        const loginDetails = response?.data;
        // console.log("loginDetails===>",loginDetails);
        const token = response?.data?.authorisation?.token;
        const handleChange = (e) => {
          const { name, value } = e.target;
          setLoginData((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        };

        localStorage.setItem("access_token_admin", token);
        localStorage.setItem("login_Details", JSON.stringify(loginDetails));
        localStorage.removeItem('user_type');
        localStorage.removeItem('user_category_main');
        window.dispatchEvent(new Event("login"));
        setToken(token); // Update the token state
        Navigate("/Organization", { replace: true });
      } else {
        notify("error", response.data.message);
        //alert(response.data.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        notify("error", error.response.data.message);
      } else {
        notify("error", "An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <div className="text-center mb-4 pt-5">
            <img src={logo} alt="" style={{ width: "140px" }} />
          </div>
          <h3 className="mb-2">Welcome back!</h3>
          <h5>USP for Sports Authority of Jharkhand</h5>
        </div>
        <div
          className="aside-image"
          style={{ backgroundImage: "url(" + loginbg + ")" }}
        ></div>
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div id="sign-in" className="auth-form   form-validation">
                  {props?.errorMessage && (
                    <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                      {props?.errorMessage}
                    </div>
                  )}
                  {props?.successMessage && (
                    <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                      {props?.successMessage}
                    </div>
                  )}
                  <form onSubmit={loginSubmit} className="form-validate">
                    <h3 className="text-center mb-4 text-black">
                      Sign in your account
                    </h3>
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="val-email">
                        <strong>Email</strong>
                      </label>
                      <div>
                        <input
                          type="email"
                          className="form-control"
                          value={loginData.email}
                          onChange={handleChange}
                          placeholder="Type Your Email Address"
                          name="email"
                        />
                      </div>
                      {errors.email && (
                        <div className="text-danger fs-12">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-1">
                        <strong>Password</strong>
                      </label>
                      <div className="password-input-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          value={loginData.password}
                          name="password"
                          placeholder="Type Your Password"
                          onChange={handleChange}
                        />
                        <EyePasswordBtn
                          showPassword={showPassword}
                          setShowPassword={setShowPassword}
                        />
                      </div>

                      {errors.password && (
                        <div className="text-danger fs-12">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div className="form-row d-flex justify-content-between mt-4 mb-2">
                      <div className="form-group mb-3">
                        <div className="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="basic_checkbox_1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="basic_checkbox_1"
                          >
                            Remember my preference
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text-center form-group mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state?.auth?.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
