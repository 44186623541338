import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import jharkhandlogo1 from "../../../images/jharkhandlogo1.png";
import swal from "sweetalert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function ViewTicket({
  showfor,
  onChangeFunc,
  value,
  handleSubmitResponse,
  viewTicketData,
  setUploadedFile,
  uploadedFile,
  isLoading,
  handleViewTicket = () => {},
  error = {},
  currentChatId,
  show,
  handleClose,
  selectedTickedDeatils, //for show the tickets detils
}) {
  const chatAreaRef = React.useRef(null);
  const fileInputRef = React.useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.webp|\.png|\.pdf)$/i;
      const maxFileSize = 2 * 1024 * 1024; // 2 MB in bytes

      if (!allowedExtensions.test(file.name)) {
        swal(
          "Error",
          "Invalid file type. Only jpg, jpeg, webp, png, and pdf files are allowed.",
          "error"
        );
        setUploadedFile(null);
        return;
      }

      if (file.size > maxFileSize) {
        swal("Error", "File size should be less than 2 MB.", "error");
        setUploadedFile(null);
        return;
      }
      setUploadedFile(file);
      swal("", "File is Selected", "success");
    }
  };
  React.useEffect(() => {
    // Make sure the scroll happens after the component is re-rendered
    const timer = setTimeout(() => {
      if (chatAreaRef.current) {
        chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
      }
    }, 100); // Delay to allow the DOM to update

    return () => clearTimeout(timer); // Clean up the timer
  }, [viewTicketData]); // Only run when replies change
  return (
    <>
      <Modal show={show} onHide={handleClose} animation={false} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {` ${selectedTickedDeatils?.ticket_unique_id} - ${selectedTickedDeatils?.title}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div className="ms-auto mb-2 ">
              <i
                style={{ fontSize: "30px" }}
                onClick={() => handleViewTicket("_", selectedTickedDeatils)}
                class="bi bi-arrow-clockwise text-primary px-2"
              ></i>
            </div>
          </div>
          <PerfectScrollbar
            style={{
              // maxHeight: "550px",
              borderRadius: "10px",
              backgroundColor: "#f4f4f4",
              padding: "10px",
            }}
            className="dlab-scroll chat-box-area"
            id="chatArea"
            options={{
              suppressScrollX: true,
              wheelPropagation: true,
              autoHide: true,
            }}
            ref={chatAreaRef}
          >
            <div className="chat-box-area dz-scroll" id="chartBox">
              {Array.isArray(viewTicketData) && viewTicketData.length > 0
                ? viewTicketData.map((ele, index) => {
                    if (showfor === "admin") {
                      if (ele.is_admin === "0") {
                        return <LeftSide key={index} ele={ele} />;
                      } else if (ele.is_admin === "1") {
                        return <RightSide key={index} ele={ele} />;
                      } else {
                        return null;
                      }
                    }
                    if (showfor === "user") {
                      if (ele.is_admin === "0") {
                        return <RightSide key={index} ele={ele} />;
                      } else if (ele.is_admin === "1") {
                        return <LeftSide key={index} ele={ele} />;
                      } else {
                        return null;
                      }
                    }
                  })
                : null}
            </div>
          </PerfectScrollbar>
          {/* comment Box */}
          <div className="card-footer border-0 type-massage">
            <div className="">
              <textarea
                maxLength={500}
                onChange={onChangeFunc}
                value={value}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="reply"
                name="replyCommentBox"
              ></textarea>
              <div>
                {error.replyCommentBox && (
                  <span className="text-danger">{error.replyCommentBox}</span>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="input-group-append d-flex justify-content-between flex-wrap">
            <div></div>
            <div>
              {/* <div> */}
              <button
                type="button"
                onClick={handleButtonClick}
                className="btn"
                disabled={isLoading}
              >
                <i className="fas fa-paperclip scale5 text-primary">
                  {uploadedFile && (
                    <>
                      <br />
                      <span>Selected</span>
                    </>
                  )}
                </i>
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              <button
                onClick={() => handleSubmitResponse(currentChatId)}
                type="button"
                className="btn btn-primary rounded text-white"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <i className="far fa-paper-plane me-2"></i>
                )}
                {isLoading ? "Sending..." : "SEND"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default React.memo(ViewTicket);

const FileCheckerRegex = /\.(jpg|jpeg|png|webp)$/i;
function LeftSide({ ele }) {
  return (
    <div className="media my-4  justify-content-start align-items-start">
      <div className="image-box me-sm-4 me-2">
        <img
          src={
            "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
          }
          alt=""
          className="rounded-circle img-1"
        />
      </div>
      <div className="message-received">
        {/* {ele?.attachement_storage_url && (
          <img
            src={ele?.attachement_storage_url}
            alt="url"
            width={"100px"}
            onClick={() => window.open(ele?.attachement_storage_url, "_blank")}
          />
        )} */}

        {ele?.attachement_storage_url &&
          (FileCheckerRegex.test(ele.attachement_storage_url) ? (
            <img
              src={ele?.attachement_storage_url}
              alt="attachment"
              width={"100px"}
              onClick={() =>
                window.open(ele?.attachement_storage_url, "_blank")
              }
            />
          ) : (
            <button
              onClick={() =>
                window.open(ele?.attachement_storage_url, "_blank")
              }
            >
              <i className="fas fa-file-pdf"></i>
              Open PDF
            </button>
          ))}

        <br />
        {ele?.description ? (
          <p
            style={{
              backgroundColor: "#D3D3D3",
              marginBottom: "1rem",
            }}
            className="mb-1 me-5 mt-2"
          >
            {ele?.description}
          </p>
        ) : null}
        <span className="fs-12">
          {moment(ele.created_at).format("dddd, MMMM Do, YYYY [at] h.mm A")}
        </span>
      </div>
    </div>
  );
}

function RightSide({ ele }) {
  return (
    <div className="media mb-4 justify-content-end align-items-end">
      <div className="message-sent">
        {ele?.attachement_storage_url &&
          (FileCheckerRegex.test(ele.attachement_storage_url) ? (
            <img
              src={ele?.attachement_storage_url}
              alt="attachment"
              width={"100px"}
              onClick={() =>
                window.open(ele?.attachement_storage_url, "_blank")
              }
            />
          ) : (
            <Button
              variant="outline-primary"
              onClick={() =>
                window.open(ele?.attachement_storage_url, "_blank")
              }
            >
              <i className="fas fa-file-pdf text-red px-2"></i>
              Document
            </Button>
          ))}

        <br />

        {ele?.description && <p className="mb-1 mt-2">{ele?.description}</p>}
        <span className="fs-12">
          {moment(ele.created_at).format("dddd, MMMM Do, YYYY [at] h.mm A")}
        </span>
      </div>
      <div className="image-box ms-sm-4 ms-2 mb-4">
        <img src={jharkhandlogo1} alt="" className="rounded-circle img-1" />
        <span className="active"></span>
      </div>
    </div>
  );
}
