
import React, { useEffect, useState } from 'react'
import PageTitle from '../../../jsx/layouts/PageTitle';
import ReactPaginate from 'react-paginate';
import { Button ,Form} from 'react-bootstrap';
import { getAllSubCategory } from '../../../services/superadminService/SubCategory';
import { Link } from 'react-router-dom';
import { getOrganisationList } from '../../../services/adminApiService/organisationApi/OrganisationApiService';
import { useNavigate } from 'react-router-dom';
import { getIndividaulList } from '../../../services/adminApiService/individualApiService/IndividualAPiService';
import { formatDateTime } from '../../../utils/dateTimeFormate';
import { formatStatus, getStatusColor } from '../../../utils/colorAsperStatus';
const IndividualList = () => {
    const [organisationdataList, setOrganisationdataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [selectedStatus, setSelectedStatus] = useState("");
    const navigate = useNavigate();

    const handleVerificationClick = (id) => {
        localStorage.removeItem('remarksIndividual');
        navigate(`/verification-Individual-form/${id}`); // Absolute path
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };
    const filteredData = selectedStatus ? organisationdataList?.data?.data?.filter((data) => data.status === selectedStatus) : organisationdataList?.data?.data;
    // console.log("filteredData==>",filteredData);
    const getOrganisationListApi = async (perPage, currentPage) => {
        setLoading(true);
        try {
            const response = await getIndividaulList(perPage, currentPage);
            console.log("responseINdividual", response);
            setOrganisationdataList(response?.data);
        } catch (error) {
            console.error('Login error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getOrganisationListApi(perPage, currentPage);
    }, [currentPage, perPage]);

    const handlePageChange = (event) => {
        // Adjusting for zero-based index by adding 1
        const selectedPage = event.selected + 1;
        setCurrentPage(selectedPage);
    };


    return (
        <>
           <PageTitle
                activeMenu="Individual List"
                motherMenu="Manage Registration"
            />
            <div className="col-12">
                <div className="card">
                <div className="card-header d-flex justify-content-end">
                        <div>
                        <Form.Select
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        className="me-2"
                        style={{ width: '150px',padding:'11px' }}
                    >
                        <option value="">All Status</option>
                        <option value="reject">Reject</option>
                        <option value="pass">Pass</option>
                        <option value="final_submit">Final Submit</option>
                        <option value="initial">Initial</option>
                        <option value="ask_for_resubmit">Ask for Resubmit</option>
                    </Form.Select>
                        </div>
                    
                      
                        {/* <div>
                            <Button variant="primary" className="mx-2" onClick={() => setShowImportModal(true)}>
                                Import Excel  <i className="fa fa-plus"></i>
                            </Button>
                        </div> */}
                    </div>
                    <div className="card-body">
                        {loading ? (
                            <div className="text-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>

                        ) : (

                            <div className="w-100 table-responsive">
                            <div id="example_wrapper" className="dataTables_wrapper">
                                <form>
                                    <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr>
                                                <th>Sr.No</th>
                                                <th>Prov.Reg.Id</th>
                                                <th>Applicant Full Name</th>
                                                <th>Email Id</th>
                                                <th>Contact Number</th>
                                                <th>Registration Date</th>
                                                <th>Attempt</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData?.length > 0 ? (
                                                filteredData.map((data, index) => {
                                                    return (
                                                        <tr key={data?.id}>
                                                            <td>{index + 1 + (currentPage - 1) * perPage}</td>  {/* Correct Serial Number */}
                                                            <td>{data?.provisinal_registration_id}</td>
                                                            <td>{data?.name}</td>
                                                            <td>{data?.email}</td>
                                                            <td>{data?.mobile}</td>
                                                            <td>{formatDateTime(data?.created_at)}</td>
                                                            <td>{data?.attempt_count + 1}</td>
                                                            <td style={{ color: getStatusColor(data?.status) }}>
                                                                {data?.status && formatStatus(data?.status)}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <Button style={{ whiteSpace: 'nowrap' }} onClick={() => handleVerificationClick(data?.id)} disabled={data?.status === "pass"}>
                                                                        Verify Application
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="9" className="text-center">Data is not available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        
                        )}

                    </div>
                </div>
                <ReactPaginate
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={8}
                    marginPagesDisplayed={7}
                    pageCount={Math.ceil(organisationdataList?.data?.total / perPage)}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    );
};

export default IndividualList;

