import React from "react";
import { addGrievanceTicket } from "../../../services/adminApiService/grievanceTicket/grievanceTicketApis";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";

// ticket form
const intialFormData = {
  title: "",
  description: "",
  ticket_category_type: "",
};
function RaiseTicketModal({
  showTicketModal,
  handleCloseTicketModal,
  fetchAllTicketData,
  currentpage,
}) {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [fileUrl, setFileUrl] = React.useState(null);
  const [formData, setformData] = React.useState(intialFormData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //set inside the form data
    let formDataConstructor = new FormData();
    for (let i in formData) {
      formDataConstructor.append(i, formData[i]);
    }
    formDataConstructor.append("attachement", selectedFile);
    let res = await addGrievanceTicket(formDataConstructor);
    if (res?.data?.status) {
      new Swal(res?.data?.message, "", "success");
      setformData(intialFormData);
      fetchAllTicketData(currentpage);
      handleCloseTicketModal();
    }
  };
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      setFileUrl(URL.createObjectURL(e.target.files[0]));
    } else {
      setSelectedFile(null);
      setFileUrl(null);
    }
  };

  const handleFilePreview = () => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    }
  };

  const handleChangeInputValue = (e) => {
    let { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  return (
    <>
      <Modal show={showTicketModal} onHide={handleCloseTicketModal}>
        <form onSubmit={handleSubmit}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Grievance Management</h4>
            <button
              type="button"
              className="btn-close"
              onClick={handleCloseTicketModal}
            >
              <span></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="row">
                  <div className="form-group mb-3 col-md-12">
                    <label className="text-black font-w500">
                      Category <span className="text-danger">*</span>
                    </label>
                    <div className="category">
                      <Form.Select
                        size="md"
                        value={formData.ticket_category_type}
                        name="ticket_category_type"
                        aria-label="select category"
                        onChange={handleChangeInputValue}
                      >
                        <option>Select Category</option>
                        <option value="Registration">Registration</option>
                        <option value="Support">Support Staff</option>
                      </Form.Select>
                    </div>
                    <span className="validation-text text-danger">
                      {Error.titleError && Error.titleError}
                    </span>
                  </div>
                </div>
                {/* 1st Row */}
                <div className="row">
                  <div className="form-group mb-3 col-md-12">
                    <label className="text-black font-w500">
                      Title <span className="text-danger">*</span>
                    </label>
                    <div className="contact-name">
                      {" "}
                      <Form.Control
                        maxLength={50}
                        required
                        name="title"
                        value={formData.title}
                        placeholder="Enter Title"
                        onChange={handleChangeInputValue}
                      />
                    </div>
                    <span className="validation-text text-danger">
                      {Error.titleError && Error.titleError}
                    </span>
                  </div>
                </div>
                {/* 2nd Row */}
                <div className="row">
                  <div className="form-group mb-3 col-md-12">
                    <label className="text-black font-w500">
                      Description <span className="text-danger">*</span>
                    </label>
                    <div className="contact-name">
                      {" "}
                      <Form.Control
                        required
                        name="description"
                        as="textarea"
                        placeholder="Description"
                        onChange={handleChangeInputValue}
                        value={formData.description}
                        maxLength={500}
                      />
                    </div>
                  </div>
                </div>
                {/* 3rd row */}
                <div className="row">
                  <div className="form-group mb-3 col-md-12">
                    <Form.Group controlId="formFile">
                      <Form.Label className="text-black font-w500">
                        Upload Document
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Control
                          type="file"
                          accept="image/*,application/pdf"
                          onChange={handleFileChange}
                        />

                        <Button
                          variant="primary"
                          className="ms-2"
                          disabled={!selectedFile}
                          onClick={handleFilePreview}
                        >
                          Preview
                        </Button>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <div className="d-flex justify-content-end w-100">
              <button
                type="button"
                className="btn btn-secondary mx-2"
                onClick={handleCloseTicketModal}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary mx-2">
                Raise
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default React.memo(RaiseTicketModal);
