import React from 'react'
import { Button, ListGroup, Modal } from 'react-bootstrap';

const ViewCategoryModal = ({viewShowModal,setViewShowModal,categoryidList}) => {
    console.log("categoryidList===>",categoryidList);
  return (
    <Modal className="fade" show={viewShowModal}>
    <Modal.Header>
        <Modal.Title>View Category</Modal.Title>
        <Button
            variant=""
            className="btn-close"
            onClick={() => setViewShowModal(false)}
        >

        </Button>
    </Modal.Header>
    <Modal.Body>
        <div className="basic-list-group">
            <ListGroup>

                <ListGroup.Item
                    className="d-flex justify-content-between align-items-center"

                >
                    <div>
                        <ul>
                            <li className="list_of_client">Category Name</li>
                            <li className="list_of_client">Parent Category</li>
                            <li className="list_of_client">Slug</li>
                            <li className="list_of_client">Status</li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li className="mb-2">{categoryidList?.category_name}</li>
                            <li className="mb-2">{categoryidList?.user_category_main_id}</li>
                            <li className="mb-2">{categoryidList?.slug}</li>
                            <li className="mb-2">{categoryidList?.active_status === "1" ? "Active":"InActive"}</li>
                        </ul>

                    </div>

                    
                </ListGroup.Item>

            </ListGroup>
        </div>
    </Modal.Body>
    <Modal.Footer>
        <Button
            onClick={() => setViewShowModal(false)}
            variant="danger light"
        >
            Close
        </Button>
        <Button variant="primary">Save changes</Button>
    </Modal.Footer>
</Modal>
  )
}

export default ViewCategoryModal