import { GET_USER_URL} from "../../config/adminUrlConfig";
import { ApiService } from "../../config/apiServices";
import { GET_CATEGORY_URL } from "../../config/superadmin/superadminUrlConfig";

export const getAllUserLevel = () => {
    return ApiService.get(GET_USER_URL);
  };


 
  