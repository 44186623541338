import { lazy, Suspense, useEffect, useState } from "react";

/// Components

import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { SuperadminloginApi } from "./services/superadminService/SuperAdminAuthService";
import { adminloginApi } from "./services/AuthService";
// import 'react-paginate/dist/react-paginate.css';
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

const TokenKey = localStorage.getItem("access_token_admin");

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const Usertoken = queryParams.get("token");
  const Usertype = queryParams.get("type");
  const user_category_main = queryParams.get("user_category_main");
  const id = queryParams.get("id");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // Check if token and type are present in the URL
        if (Usertoken && Usertype === 'user') {
          // Set the token in localStorage
          localStorage.setItem('access_token_admin', Usertoken);
          localStorage.setItem('user_type', Usertype);
          localStorage.setItem('user_category_main', user_category_main);
          localStorage.setItem('id', id);
          localStorage.removeItem("login_Details");
          // Set isAuthenticated to true
          setIsAuthenticated(true);
          // Redirect to the profile page
          navigate('/profile'); // Adjust the route as per your profile page
        } else {
          // Check localStorage for the existing token
          const token = localStorage.getItem('access_token_admin');
          setIsAuthenticated(!!token);
        }
      }, [Usertoken, Usertype, navigate]);

  let routesBlog = (
    <Routes>
      <Route path="/" element={<Login apifun={adminloginApi} />} />
      <Route path="/admin-login" element={<Login apifun={adminloginApi} />} />
      <Route
        path="/super-admin-login"
        element={<Login apifun={SuperadminloginApi} />}
      />

      <Route path="/page-register" element={<SignUp />} />
      <Route path="/page-forgot-password" element={<ForgotPassword />} />
      {/* <Route path='*' element={<Navigate to="/super-admin-login" replace />} /> */}
    </Routes>
  );
  if (isAuthenticated) {
    //console.log("isAuthenticated",isAuthenticated);
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routesBlog}
        </Suspense>
      </div>
    );
  }
}

export default withRouter(App);
