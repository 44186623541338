import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { ListGroup, Modal } from 'react-bootstrap';



import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';






import ReactPaginate from 'react-paginate';

import PageTitle from '../../../jsx/layouts/PageTitle';
import Editable from '../../../jsx/pages/Editable';
import ClientAddModal from '../../../jsx/components/SuperAdmin/client/ClientAddModal';
import ClientEditModal from '../../../jsx/components/SuperAdmin/client/ClientEditModal';
import { getAllSubCategory, getSubCategoryById } from '../../../services/superadminService/SubCategory';
import AddCategoryModal from '../categoryType/AddCategoryModal';
import AddSubCategoryModal from './AddSubCategoryModal';
import EditSubCategoryModal from './EditSubCategoryModal';
import { getAllCategoryType } from '../../../services/superadminService/CategoreyType';

const SubCategoryList = () => {
    const perPage = 10;
    const [addCard, setAddCard] = useState(false);
    const [contents, setContents] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [basicModal, setBasicModal] = useState(false);
    const [subCategoryIdData, setSubCategoryIdData] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [editContentId, setEditContentId] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [categoryTypeListData, setCategoryTypeListData] = useState([]);






    // Edit function button click to edit
    const handleEditClick = (clientID) => {
        setShowEditModal(true)
        setClientId(clientID)
        SubCategoryListByIdApi(clientID)

    };

    // edit  data  
    const [editFormData, setEditFormData] = useState({
        name: '',
        department: '',
        gender: '',
        education: '',
        mobile: '',
        email: '',
    })

    //update data function
    const handleEditFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldValue;
        setEditFormData(newFormData);
    };

    // edit form data submit
    const handleFormSubmit = (event) => {
        event.preventDefault();
        const editedContent = {
            id: editContentId,
            name: editFormData.name,
            department: editFormData.department,
            gender: editFormData.gender,
            education: editFormData.education,
            mobile: editFormData.mobile,
            email: editFormData.email,
        }
        const newContents = [...contents];
        const index = contents.findIndex((content) => content.id === editContentId);
        newContents[index] = editedContent;
        setContents(newContents);
        setEditContentId(null);
        // setEditModal(false);
    }
    //Cencel button to same data
    const handleCancelClick = () => {
        setEditContentId(null);
    };

    const viewClientFun = (clientId) => {
        SubCategoryListByIdApi(clientId)
        setBasicModal(true)
    }

    const OpenlogoupdateModal = (clientId) => {
        setClientId(clientId)
        // setShowlogoModal(true)
    }





    const SubCategoryListByIdApi = async (categoryId) => {
        try {

            const response = await getSubCategoryById(categoryId);
            setSubCategoryIdData(response?.data)

        } catch (error) {
            console.error('Login error:', error);
        }
    }

    const CategorySubListApi = async (pageNumber = 1) => {

        try {
            const response = await getAllSubCategory(pageNumber);

            setClientData(response?.data || []);



        } catch (error) {
            console.error('Login error:', error);
        }
    }

    const handlePageChange = ({ selected }) => {
        CategorySubListApi((selected + 1))
        setCurrentPage(selected + 1);
    };

    const CategoryTypeListApi = async () => {
        try {
            const response = await getAllCategoryType();
            //console.log("responseClient===>", response.data.data);
            setCategoryTypeListData(response?.data || []);



        } catch (error) {
            console.error('Login error:', error);
        }
    }
    useEffect(() => {
        CategoryTypeListApi();
    }, []);

    useEffect(() => {
        CategorySubListApi(currentPage);
        // CategoryTypeListApi();
    }, [currentPage]);
    return (
        <>
            <PageTitle activeMenu="Table" motherMenu="Post" />
            <div className="col-12">
                <ClientAddModal addCard={addCard} setAddCard={setAddCard} />
                <div className="card">
                    <div className="card-header d-flex">
                        <div>
                            <div className="input-group search-area">
                                <input type="text"
                                    className={`form-control `}
                                    placeholder="Search here..."
                                />
                                <span className="input-group-text" >
                                    <Link to={"#"}><i className="flaticon-381-search-2"></i></Link>
                                </span>
                            </div>
                        </div>
                        <div>
                            <Button variant="primary" onClick={() => setShowAddModal(true)} className="mx-2">Add Sub Category  <i className="fa fa-plus" ></i></Button>
                        </div>

                    </div>
                    <div className="card-body">
                        <div className="w-100 table-responsive">
                            <div id="example_wrapper" className="dataTables_wrapper">
                                <form onSubmit={handleFormSubmit}>
                                    <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Category Name</th>
                                                <th>Slug</th>
                                                <th> Parent category</th>
                                                <th>status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clientData?.data?.length > 0 ? (
                                                clientData?.data.map((data, index) => {

                                                    return (
                                                        <tr key={index}>
                                                            {editContentId === data?.id ? (
                                                                <Editable
                                                                    editFormData={editFormData}
                                                                    handleEditFormChange={handleEditFormChange}
                                                                    handleCancelClick={handleCancelClick}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <td>{data.id}</td>
                                                                    <td>{data?.sub_category_name}</td>
                                                                    <td>{data?.url_slug}</td>
                                                                    <td>{data?.usercategorytype?.category_name}</td>
                                                                    <td>{data?.active_status === "1" ? "Active" : "InActive"}</td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <Link
                                                                                className="btn btn-secondary shadow btn-xs sharp me-2"
                                                                                onClick={(event) => handleEditClick(data?.id)}
                                                                            >
                                                                                <i className="fas fa-pen"></i>
                                                                            </Link>
                                                                            <Link
                                                                                className="btn btn-danger shadow btn-xs sharp me-2"
                                                                                onClick={() => viewClientFun(data?.id)}
                                                                            >
                                                                                <i className="fa fa-eye"></i>
                                                                            </Link>

                                                                        </div>
                                                                    </td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" className="text-center">Data is not available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>



                <ReactPaginate
                    nextLabel="next >"
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={8}
                    marginPagesDisplayed={7}
                    pageCount={Math.ceil(clientData?.total / perPage)}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />

            </div>

            <Modal className="fade" show={basicModal}>
                <Modal.Header>
                    <Modal.Title>View Client</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setBasicModal(false)}
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="basic-list-group">
                        <ListGroup>

                            <ListGroup.Item
                                className="d-flex justify-content-between align-items-center"

                            >
                                <div>
                                    <ul>
                                        <li className="list_of_client">Category Name</li>
                                        <li className="list_of_client">Parent Category</li>
                                        <li className="list_of_client">Slug</li>
                                        <li className="list_of_client">Status</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li className="mb-2">{subCategoryIdData?.sub_category_name}</li>
                                        <li className="mb-2">{subCategoryIdData?.user_category_types_id}</li>
                                        <li className="mb-2">{subCategoryIdData?.url_slug}</li>
                                        <li className="mb-2">{subCategoryIdData?.active_status === "1" ? "Active" : "InActive"}</li>

                                    </ul>

                                </div>

                                {/* <Badge variant="primary" pill>
                        {i + 1}
                      </Badge> */}
                            </ListGroup.Item>

                        </ListGroup>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setBasicModal(false)}
                        variant="danger light"
                    >
                        Close
                    </Button>
                    <Button variant="primary">Save changes</Button>
                </Modal.Footer>
            </Modal>
            <AddSubCategoryModal showAddModal={showAddModal} setShowAddModal={setShowAddModal} categoryTypeListData={categoryTypeListData} CategorySubListApi={CategorySubListApi} />
            <EditSubCategoryModal showEditModal={showEditModal} setShowEditModal={setShowEditModal} ClientId={clientId} subCategoryIdData={subCategoryIdData} CategorySubListApi={CategorySubListApi} />
        </>
    )
}

export default SubCategoryList